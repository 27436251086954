import { AspectRatio, Burger, Flex, Header as MantineHeader, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Dispatch, SetStateAction } from 'react';
import Image from 'next/image';

type HeaderProps = {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
};

export default function Header({ opened, setOpened }: HeaderProps) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  if (!isMobile) return null;

  return (
    <MantineHeader height={{ base: 70 }} p="md">
      <Flex h="100%" align="center" pos="relative">
        <Burger
          opened={opened}
          onClick={() => setOpened((o) => !o)}
          size="sm"
          color={theme.colors.lightText[6]}
          mr="xl"
        />
        <AspectRatio pos="absolute" m="auto" left="0" right="0" ratio={104 / 43} w={104} h={43}>
          <Image src="/assets/logos/logo-dark.png" fill alt="" style={{ objectFit: 'cover' }} />
        </AspectRatio>
      </Flex>
    </MantineHeader>
  );
}
