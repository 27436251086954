import { MantineSizes } from '@mantine/core';
import defaultTheme from '../styles/themes/default';

export function mediaQuery(
  min: keyof MantineSizes | number,
  max?: keyof MantineSizes | boolean | number,
) {
  const breakpoints = defaultTheme.breakpoints as MantineSizes;

  const minBreakpoint = typeof min === 'number' ? `${min}px` : breakpoints[min];
  const maxBreakpoint =
    typeof max === 'boolean' ? 0 : typeof max === 'number' ? `${max}px` : breakpoints[max || 'xs'];

  if (typeof max === 'boolean') return `@media screen and (max-width:${minBreakpoint})`;
  if (!max) return `@media screen and (min-width:${minBreakpoint})`;

  return `@media screen and (min-width:${minBreakpoint}) and (max-width:${maxBreakpoint})`;
}
