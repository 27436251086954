import { MantineThemeOverride } from '@mantine/core';

import { Montserrat } from 'next/font/google';

const montserrat = Montserrat({
  subsets: ['latin'],
  weight: ['300', '400', '500', '600', '700', '800'],
});

const globalStyles: MantineThemeOverride['globalStyles'] = (_theme) => ({
  '*, *::before, *::after': {
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
    scrollMarginTop: '100px',
  },
  'body, html': {
    maxWidth: '100vw',
    overflowX: 'hidden',
    height: '100%',
    fontFamily: montserrat.style.fontFamily,
    fontWeight: montserrat.style.fontWeight,
  },
  a: {
    color: 'inherit',
  },
  '.sr-only': {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: '0',
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap',
    borderWidth: '0',
  },
  img: {
    pointerEvents: 'none',
  },
});

export default globalStyles;
