import {
  IconWallpaper,
  IconWind,
  IconFolderPlus,
  IconHome,
  IconUsers,
  TablerIconsProps,
  IconColorSwatch,
  IconGlassFull,
  IconCircles,
  IconCircleSquare,
  IconColorFilter,
  IconComponents,
  IconBuildingCommunity,
  IconCompass,
  IconCategory,
  IconMap,
  IconMovie,
  IconWorld,
  IconArticle,
  IconTag,
  IconUsersGroup,
  IconPremiumRights,
  IconCalendarEvent,
  IconDeviceTv,
  IconUserPlus,
} from '@tabler/icons-react';
import { IconBaseProps, IconType } from 'react-icons';
import { Role } from './roles';

export type Route = {
  title: string;
  icon: IconType | ((props: TablerIconsProps | IconBaseProps) => JSX.Element);
  path?: string;
  roles?: Role[];
  submenus?: Route[];
};

export const adminRoutes: Route[] = [
  {
    title: 'Dashboard',
    path: '/admin',
    icon: IconHome,
  },
  { title: 'Portais', path: '/admin/portais', icon: IconFolderPlus },
  { title: 'Banners', path: '/admin/banners', icon: IconWallpaper },

  {
    title: 'Degustação',
    icon: IconGlassFull,
    roles: ['admin'],
    submenus: [
      { title: 'Aromas', path: '/admin/aromas', icon: IconWind },
      { title: 'Cores', path: '/admin/cores-de-vinho', icon: IconColorFilter },
      {
        title: 'Famílias de aroma',
        path: '/admin/familias-de-aroma',
        icon: IconComponents,
        roles: ['admin'],
      },
      {
        title: 'Categorias de cor',
        path: '/admin/categorias-de-cor',
        icon: IconColorSwatch,
        roles: ['admin'],
      },
      {
        title: 'Estilos de vinho',
        path: '/admin/estilos-de-vinho',
        icon: IconCircleSquare,
        roles: ['admin'],
      },
      {
        title: 'Tipos de vinho',
        path: '/admin/tipos-de-vinho',
        icon: IconCircles,
        roles: ['admin'],
      },
    ],
  },
  {
    title: 'Enoturismo',
    icon: IconCompass,
    roles: ['admin'],
    submenus: [
      { title: 'Eventos', path: '/admin/eventos', icon: IconCalendarEvent },
      { title: 'Estabelecimentos', path: '/admin/estabelecimentos', icon: IconBuildingCommunity },
      { title: 'Categorias', path: '/admin/categorias-de-estabelecimento', icon: IconCategory },
      { title: 'Países ou Regiões', path: '/admin/regioes', icon: IconMap },
    ],
  },
  {
    title: 'Website',
    icon: IconWorld,
    roles: ['admin'],
    submenus: [
      { title: 'Blog', path: '/admin/blog', icon: IconArticle },
      { title: 'Tags', path: '/admin/tags', icon: IconTag },
      { title: 'Vídeos do Canal', path: '/admin/videos-do-canal', icon: IconMovie },
      { title: 'Categorias de Video', path: '/admin/categorias-de-video', icon: IconCategory },
    ],
  },
  { title: 'Confrarias', path: '/admin/confrarias', icon: IconUsersGroup, roles: ['admin'] },
  {
    title: 'Smart TV',
    icon: IconDeviceTv,
    roles: ['admin'],
    submenus: [{ title: 'Banners', path: '/admin/smart-tv-banners', icon: IconWallpaper }],
  },
  { title: 'Usuários', path: '/admin/usuarios', icon: IconUsers, roles: ['admin'] },
  { title: 'Inscritos', path: '/admin/inscritos', icon: IconUserPlus, roles: ['admin'] },
  { title: 'Assinantes', path: '/admin/assinantes', icon: IconPremiumRights, roles: ['admin'] },
];

export const advertiserRoutes: Route[] = [
  {
    title: 'Dashboard',
    path: '/anunciante',
    icon: IconHome,
  },
  {
    title: 'Meus estabelecimentos',
    path: '/anunciante/estabelecimentos',
    icon: IconBuildingCommunity,
  },
  {
    title: 'Assinatura',
    path: '/anunciante/assinatura',
    icon: IconPremiumRights,
  },
];

export function canAccessRoute(path: string, userRole?: string): boolean {
  const route = adminRoutes.find((route) => route.path === path);
  return route?.roles?.some((role) => role === userRole) ?? true;
}
