import { useAuth } from '@/providers/AuthProvider/AuthProvider';
import { getInitials } from '@/utils/user';
import {
  Avatar,
  Menu,
  Navbar as MantineNavbar,
  AspectRatio,
  useMantineTheme,
  ActionIcon,
  Text,
  ScrollArea,
  Flex,
  Button,
  MediaQuery,
  Box,
  Accordion,
  Skeleton,
} from '@mantine/core';
import { IconChevronLeft, IconChevronRight, IconLogout } from '@tabler/icons-react';
import Image from 'next/image';
import { useState, useMemo, useEffect } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Route } from '@/utils/routes';
import { getRoleLabel } from '@/utils/roles';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

type NavComponentsProps<T = Record<string, unknown>> = {
  collapsed: boolean;
  routes?: Route[];
  subRoutes?: Route[];
} & T;

function NavbarHeader({ collapsed }: NavComponentsProps) {
  return (
    <AspectRatio
      pos="relative"
      ratio={collapsed ? 1 : 158 / 65}
      w={collapsed ? 60 : 158}
      h={60}
      m="auto"
    >
      <Image
        src={collapsed ? '/assets/logos/icon-dark.png' : '/assets/logos/logo-dark.png'}
        fill
        alt=""
      />
    </AspectRatio>
  );
}

type UserSectionProps = {
  isMobile?: boolean;
};

function UserSection({ collapsed, isMobile }: NavComponentsProps<UserSectionProps>) {
  const { user, logout } = useAuth();

  if (!user) return null;

  const initials = getInitials(user?.name);

  if (collapsed)
    return (
      <Menu
        shadow="lg"
        width={isMobile ? '100%' : 'auto'}
        position={isMobile ? 'top' : 'right-start'}
      >
        <Menu.Target>
          <Flex gap="md" px="md" justify="center" align="center" sx={{ cursor: 'pointer' }}>
            <Avatar size="md" title={user.name} variant="gradient" radius="xl">
              {initials}
            </Avatar>
          </Flex>
        </Menu.Target>
        <Menu.Dropdown p="md">
          <Flex direction="column" mb="md" w="max-content">
            <Text fw="500">{user.name}</Text>
            <Text>{getRoleLabel(user.role)}</Text>
            {/*             {!user.email?.includes('@cpf.com') && <Text>{user.email}</Text>} */}
          </Flex>
          <Button fullWidth variant="outline" leftIcon={<IconLogout />} onClick={() => logout([])}>
            Sair
          </Button>
        </Menu.Dropdown>
      </Menu>
    );

  return (
    <Accordion radius={0} styles={{ item: { border: '1px solid #0001' } }}>
      <Accordion.Item value="user-menu">
        <Accordion.Control>
          <Flex gap="md" px="md" justify="left" align="center" sx={{ cursor: 'pointer' }}>
            <Avatar size="md" title={user.name} variant="gradient" radius="xl">
              {initials}
            </Avatar>

            <Flex direction="column">
              <Text fw="bold">{user.name}</Text>
              <Text>{getRoleLabel(user.role)}</Text>
            </Flex>
          </Flex>
        </Accordion.Control>
        <Accordion.Panel>
          <Box ta="center" mb="md">
            {/*  {!user.email?.includes('@cpf.com') && <Text>{user.email}</Text>} */}
          </Box>
          <Button fullWidth variant="outline" leftIcon={<IconLogout />} onClick={() => logout([])}>
            Sair
          </Button>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}

type NavFooterProps = {
  setCollapsed: (collapsed: boolean) => void;
};

function NavFooter({ collapsed, setCollapsed }: NavComponentsProps<NavFooterProps>) {
  return (
    <Box p="md" mt="auto">
      <ActionIcon
        title={`${collapsed ? 'Expandir' : 'Recolher'}`}
        color="primary"
        variant="subtle"
        w="100%"
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? <IconChevronRight /> : <IconChevronLeft />}
      </ActionIcon>
    </Box>
  );
}

const SubRouteIcon: React.FC<{ collapsed: boolean; showSubRoutes: boolean }> = ({
  collapsed,
  showSubRoutes,
}) => {
  return showSubRoutes ? (
    <BsChevronUp style={collapsed ? { position: 'absolute', right: 0 } : {}} size={14} />
  ) : (
    <BsChevronDown style={collapsed ? { position: 'absolute', right: 0 } : {}} size={14} />
  );
};

const RouteItem: React.FC<{
  route: Route;
  routes: Route[];
  collapsed: boolean;
  onClick?: () => void;
}> = ({ route, routes, collapsed }) => {
  const router = useRouter();
  const { icon: Icon, title, path, submenus } = route;

  const [showSubRoutes, setShowSubroutes] = useState(false);

  const commonStyles = {
    w: '100%',
    size: 'md',
    color: 'primary',
    fw: '500',
    fz: 'lg',
  };

  const extraProps = path
    ? ({
        component: Link,
        href: path,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as { component: any; href: string })
    : {
        rightIcon: <SubRouteIcon showSubRoutes={showSubRoutes} collapsed={collapsed} />,
        onClick: () => {
          setShowSubroutes(!showSubRoutes);
        },
      };

  return (
    <>
      {collapsed ? (
        <ActionIcon
          {...commonStyles}
          {...extraProps}
          variant={router.pathname === path ? 'light' : 'subtle'}
          title={title}
          key={title}
          p="md"
        >
          {<Icon />}
          {submenus && <SubRouteIcon showSubRoutes={showSubRoutes} collapsed={collapsed} />}
        </ActionIcon>
      ) : (
        <Button
          {...commonStyles}
          {...extraProps}
          variant={router.pathname === path ? 'light' : 'subtle'}
          leftIcon={<Icon />}
          key={title}
          styles={{
            inner: {
              justifyContent: 'flex-start',
            },
            rightIcon: {
              marginLeft: 'auto',
            },
          }}
        >
          {title}
        </Button>
      )}
      {showSubRoutes && (
        <Flex direction="column" ml={collapsed ? 0 : 10}>
          <NavLinks collapsed={collapsed} key={title} subRoutes={submenus} routes={routes} />
        </Flex>
      )}
    </>
  );
};

function NavLinks({ collapsed, subRoutes, routes }: NavComponentsProps<{ routes: Route[] }>) {
  const { user } = useAuth();

  const routesToRender = useMemo(() => {
    if (!user) return [];

    return (
      subRoutes ||
      routes.filter(({ roles }) => {
        if (!roles || !roles.length) return true; // If no roles are specified, render route
        return roles.includes(user.role); // If user role is in roles array, render route
      })
    );
  }, [subRoutes, user]);

  return (
    <>
      {routesToRender.map((route) => (
        <RouteItem key={route.title} route={route} collapsed={collapsed} routes={routes} />
      ))}
    </>
  );
}

export default function Navbar({ opened, routes }: { opened: boolean; routes: Route[] }) {
  const theme = useMantineTheme();
  const [collapsed, setCollapsed] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  // Doing this to prevent className did not match Server/Client
  // Doing so, the component will only show on first render
  // and the server won't need to handle the conflicting styles
  // https://github.com/vercel/next.js/issues/7322
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (isMobile && collapsed) setCollapsed(false);

  if (isMobile && !opened) return null;

  return (
    <MantineNavbar fixed width={{ xs: '100%', sm: collapsed ? 120 : 300 }}>
      {!hasMounted ? (
        <Skeleton />
      ) : (
        <>
          <MediaQuery query={`(max-width: ${theme.breakpoints.sm})`} styles={{ display: 'none' }}>
            <Box my="md">
              <NavbarHeader {...{ collapsed }} />
            </Box>
          </MediaQuery>

          <UserSection {...{ collapsed, isMobile }} />
          <ScrollArea
            mah="100%"
            p="md"
            // Hide horizontal scroll
            sx={() => ({ 'div[data-orientation="horizontal"]': { display: 'none' } })}
          >
            <Flex
              direction="column"
              gap="md"
              sx={() => ({
                svg: {
                  fontSize: '24px',
                },
              })}
            >
              <NavLinks {...{ collapsed }} routes={routes} />
            </Flex>
          </ScrollArea>
          {!isMobile && <NavFooter {...{ collapsed, setCollapsed }} />}
        </>
      )}
    </MantineNavbar>
  );
}
