const portalIconsBasePath = '/assets/portalIcons';
const socialIconsBasePath = '/assets/socialIcons';

export const portalIconsConstraints = {
  'Icon-1': `${portalIconsBasePath}/Icon-1.svg`,
  'Icon-2': `${portalIconsBasePath}/Icon-2.svg`,
  'Icon-3': `${portalIconsBasePath}/Icon-3.svg`,
  'Icon-4': `${portalIconsBasePath}/Icon-4.svg`,
  'Icon-5': `${portalIconsBasePath}/Icon-5.svg`,
  'Icon-6': `${portalIconsBasePath}/Icon-6.svg`,
  'Icon-7': `${portalIconsBasePath}/Icon-7.svg`,
  'Icon-8': `${portalIconsBasePath}/Icon-8.svg`,
  'Icon-9': `${portalIconsBasePath}/Icon-9.svg`,
  'Icon-10': `${portalIconsBasePath}/Icon-10.svg`,
  'Icon-11': `${portalIconsBasePath}/Icon-11.svg`,
  'Icon-12': `${portalIconsBasePath}/Icon-12.svg`,
  'Icon-13': `${portalIconsBasePath}/Icon-13.svg`,
  'Icon-14': `${portalIconsBasePath}/Icon-14.svg`,
  'Icon-15': `${portalIconsBasePath}/Icon-15.svg`,
  'Icon-16': `${portalIconsBasePath}/Icon-16.svg`,
  'Icon-17': `${portalIconsBasePath}/Icon-17.svg`,
  'Icon-18': `${portalIconsBasePath}/Icon-18.svg`,
  'Icon-19': `${portalIconsBasePath}/Icon-19.svg`,
  'Icon-20': `${portalIconsBasePath}/Icon-20.svg`,
  'Icon-21': `${portalIconsBasePath}/Icon-21.svg`,
  'Icon-22': `${portalIconsBasePath}/Icon-22.svg`,
  'Icon-23': `${portalIconsBasePath}/Icon-23.svg`,
  'Icon-24': `${portalIconsBasePath}/Icon-24.svg`,
  'Icon-Barrel': `${portalIconsBasePath}/Icon-Barrel.svg`,
};

export const socialIconsConstraints = {
  'Icon-Facebook-1': `${socialIconsBasePath}/Icon-Facebook-1.svg`,
  'Icon-Instagram-1': `${socialIconsBasePath}/Icon-Instagram-1.svg`,
  'Icon-Linkedin-1': `${socialIconsBasePath}/Icon-Linkedin-1.svg`,
  'Icon-Twitter-1': `${socialIconsBasePath}/Icon-Twitter-1.svg`,
  'Icon-Youtube-1': `${socialIconsBasePath}/Icon-Youtube-1.svg`,
  'Icon-Tiktok-1': `${socialIconsBasePath}/Icon-Tiktok-1.svg`,
  'Icon-Pinterest-1': `${socialIconsBasePath}/Icon-Pinterest-1.svg`,
  'Icon-Email-1': `${socialIconsBasePath}/Icon-Email-1.svg`,
  'Icon-Globe-1': `${socialIconsBasePath}/Icon-Globe-1.svg`,
  'Icon-Link-1': `${socialIconsBasePath}/Icon-Link-1.svg`,
};
