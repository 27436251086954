import { css } from '@emotion/react';
import { MantineTheme, MantineThemeOverride, rem } from '@mantine/core';
import darkTheme, { colors } from './dark';

export type OtherType = typeof other;
export type ColorsType = keyof typeof colors;

const breakpoints = {
  xs: rem(576) as '576px',
  sm: rem(768) as '768px',
  md: rem(992) as '992px',
  lg: rem(1200) as '1200px',
  xl: rem(1400) as '1400px',
};

const sizing = {
  size0: rem(0) as '0px',
  size1: rem(2) as '2px',
  size2: rem(4) as '4px',
  size3: rem(8) as '8px',
  size4: rem(16) as '16px',
  size5: rem(32) as '32px',
  size6: rem(64) as '64px',
  size7: rem(128) as '128px',
  size8: rem(256) as '256px',
  size9: rem(512) as '512px',
  size10: rem(1024) as '1024px',
};

const defaultCSS = {
  center: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  centerColumn: css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `,
  label: css`
    font-size: ${rem(16)};
    margin-bottom: ${rem(2)};
    display: flex;
    align-items: center;
    gap: ${rem(12)};
    flex-shrink: 0;
    font-weight: 500;
  `,
  srOnly: css`
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  `,
};

export const other = {
  sizing,
  css: defaultCSS,
};

const headings: MantineThemeOverride['headings'] = {
  fontWeight: 500,
  sizes: {
    h1: {
      fontSize: rem(38),
      lineHeight: rem(42),
    },
    h2: {
      fontSize: rem(38),
      lineHeight: rem(42),
    },
    h3: {
      fontSize: rem(38),
      lineHeight: rem(42),
    },
    h4: {
      fontSize: rem(38),
      lineHeight: rem(42),
    },
    h5: {
      fontSize: rem(38),
      lineHeight: rem(42),
    },
    h6: {
      fontSize: rem(38),
      lineHeight: rem(42),
    },
  },
};

const InputStyles = (theme: MantineTheme) => ({
  input: {
    borderRadius: rem(12),
    color: theme.colors.lightText,
    fontSize: rem(18),
    fontWeight: 500,
  },
  label: {
    fontSize: rem(16),
    color: theme.colors.lightText,
    marginBottom: rem(2),
    display: 'flex',
    alignItems: 'center',
    gap: rem(12),
    flexShrink: 0,
    '& svg': {
      minWidth: rem(18),
    },
  },
  root: {
    borderRadius: rem(15),
    padding: `${rem(20)} ${rem(20)}`,
  },
  description: {
    color: theme.colors.lightText[6],
    fontSize: rem(16),
  },
  error: {
    fontSize: rem(16),
  },
});

const components = {
  Select: {
    defaultProps: {
      styles: (theme: MantineTheme) => ({
        ...InputStyles(theme),
        icon: {
          width: rem(50),
        },
      }),
    },
  },
  MultiSelect: {
    defaultProps: {
      styles: (theme: MantineTheme) => InputStyles(theme),
    },
  },
  Input: {
    defaultProps: {
      styles: (theme: MantineTheme) => InputStyles(theme),
    },
  },
  InputWrapper: {
    defaultProps: {
      styles: (theme: MantineTheme) => ({
        ...InputStyles(theme),
        root: {
          padding: 0,
        },
      }),
    },
  },
  TextInput: {
    defaultProps: {
      styles: (theme: MantineTheme) => InputStyles(theme),
    },
  },
  PasswordInput: {
    defaultProps: {
      styles: (theme: MantineTheme) => InputStyles(theme),
    },
  },
  Textarea: {
    defaultProps: {
      styles: (theme: MantineTheme) => InputStyles(theme),
    },
  },
  Button: {
    defaultProps: {
      styles: () => ({
        root: {
          '&:hover': {
            opacity: 0.8,
          },
        },
        icon: {
          fontSize: rem(18),
        },
      }),
      radius: rem(12),
    },
  },
};

const defaultTheme: MantineThemeOverride = {
  ...darkTheme,
  other,
  breakpoints,
  headings,
  components,
};

export default defaultTheme;
