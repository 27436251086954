import React from 'react';
import { create, StoreApi, UseBoundStore } from 'zustand';
import { createFirebaseSlice, FirebaseSlice } from './slices/FirebaseSlice';

export type PreloadedState = {
  firebaseSliceInitialData: {
    [key: string]: unknown;
  };
};

export type AppStore = FirebaseSlice;

const INITIAL_STATE = {} as AppStore;

let store: UseBoundStore<StoreApi<AppStore>> | undefined;

const createNewStore = (preloadedState: PreloadedState) =>
  create<AppStore>((...args) => ({
    ...createFirebaseSlice(...args),
    ...{ ...INITIAL_STATE, ...preloadedState },
  }));

export const initializeStore = (preloadedState: PreloadedState) => {
  let _store = store ?? createNewStore(preloadedState);

  // After navigating to a page with an initial Zustand state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = createNewStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useHydrate(initialState: PreloadedState) {
  const state = typeof initialState === 'string' ? JSON.parse(initialState) : initialState;
  const store = React.useMemo(() => initializeStore(state), [state]);
  return store;
}

export default initializeStore;
