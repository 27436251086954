import { mediaQuery } from '@/utils/mediaQuery';
import {
  Flex,
  UnstyledButtonProps,
  createPolymorphicComponent,
  UnstyledButton,
} from '@mantine/core';
import styled from '@emotion/styled';

interface HeaderProps {
  fixed?: boolean;
}

export const Header = styled.header<HeaderProps>`
  position: ${(props) => (props.fixed ? 'fixed' : 'initial')};
  width: 100%;
  height: 100px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  border-top: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to left,
    ${(props) => props.theme.colors.lightRed[6]},
    ${(props) => props.theme.colors.darkRed[6]}
  );
  background-color: ${(props) => props.theme.colors.darkBackground[6]};
`;

export const StyledFlex = styled(Flex)`
  padding: 0 15px 0 0;
  height: 100%;
  color: ${(props) => props.theme.colors.lightText};
  ${mediaQuery(1130)} {
    padding: 0 15px 0 15px;
  }
`;

interface NavLinkProps {
  mobile?: boolean;
}

export const NavLink = styled.li<NavLinkProps>`
  display: none;
  select {
    cursor: pointer;
  }
  margin: ${(props) => (props.mobile ? '0 0 2rem 0' : '0 0 0 2rem')};
  & a {
    color: ${(props) => props.theme.colors.lightText};
    text-decoration: none;
    text-transform: uppercase;
  }
  &[data-mobile] {
    display: block;
  }
  ${mediaQuery(1130)} {
    display: ${(props) => (props.mobile ? 'block' : 'inline-block')};
  }
`;

export const LangSelect = styled.div`
  margin-left: auto;
  margin-right: 1rem;
  width: 75px;
  padding: 0;
  .mantine-Select-input {
    color: transparent;

    &::selection,
    &::-moz-selection {
      background: transparent;
    }
  }
  ${mediaQuery(1130)} {
    display: none;
  }
`;

export const BurgerMenu = styled.div`
  margin-right: auto;
  height: 100%;
  padding: 30px 15px;

  ${mediaQuery(1130)} {
    display: none;
  }
  ${mediaQuery('xs')} {
    padding: 30px;
  }
`;

export const _BurgerButton = styled(UnstyledButton)<UnstyledButtonProps>`
  ${mediaQuery(1130)} {
    display: none;
  }
`;

export const BurgerButton = createPolymorphicComponent<'button', UnstyledButtonProps>(
  _BurgerButton,
);
