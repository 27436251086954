import { AppStore } from '@/stores/store';
import React from 'react';
import { StoreApi, UseBoundStore } from 'zustand';

type Store = UseBoundStore<StoreApi<AppStore>>;
type Context = Store | null;
type Props = { children: React.ReactNode; store: Context };

export const StoreContext = React.createContext<Context>(null);

export const StoreProvider: React.FC<Props> = ({ children, store }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

type Hook = <T>(
  selector: (state: AppStore) => T,
  equals?: (a: unknown, b: unknown) => boolean,
) => T;

export const useStore: Hook = (selector, equals) => {
  const store = React.useContext(StoreContext) as Store;
  const values = store(selector, equals);

  return values as ReturnType<typeof selector>;
};
