import ShadeGenerator, { Shade } from 'shade-generator';

// Mantine colors scheme needs an array with 10 shades. To keep all shades the same use this method
export const createColorArray = (color: string) => {
  const config: Record<Shade, number> = {
    '10': 0,
    '20': 0,
    '30': 0,
    '40': 0.9,
    '50': 0.75,
    '60': 0.6,
    '70': 0.45,
    '80': 0.3,
    '90': 0.15,
    '100': 0,
    '200': 0.9,
    '300': 0.8,
    '400': 0.7,
    '500': 0,
    '600': 0,
    '700': 0,
    '800': 0,
    '900': 0,
    '1000': 0,
  };

  const colorMap = ShadeGenerator.hue(color).config(config).shadesMap('hex');
  const colors = [
    colorMap['40'],
    colorMap['50'],
    colorMap['60'],
    colorMap['70'],
    colorMap['80'],
    colorMap['90'],
    colorMap['100'],
    colorMap['200'],
    colorMap['300'],
    colorMap['400'],
  ];

  return colors;
};
