function getElementAndScroll(query: string, _offset = 100) {
  const element = document.querySelector(`${query}`);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
    // const top =
    //   element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset;
    // window.scrollTo({ behavior: 'smooth', top });
  }
}

export function scrollTo(query: string, offset = 100, from?: string) {
  if (typeof window === 'undefined') return;
  if (from?.includes('/') && from !== '/') {
    setTimeout(function () {
      getElementAndScroll(query, offset);
    }, 1000);
  } else {
    getElementAndScroll(query, offset);
  }
}
