import { User } from '@/typings/user';
import {
  checkAndCreateUserOnFirestore,
  createData,
  firebaseAuth,
  getQuery,
  updateData,
} from '@/utils/firebase';
import { getMessage, getUserMessage, UserOnlyErrorCodes } from '@/utils/firebaseAuth';
import { adminRoutes } from '@/utils/routes';
import { useLocalStorage } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useRollbar } from '@rollbar/react';
import { deleteCookie, setCookie } from 'cookies-next';
import {
  AuthError,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User as AuthUser,
} from 'firebase/auth';
import { onSnapshot, where } from 'firebase/firestore';
import { useRouter } from 'next/router';
import React from 'react';
import { USER_KEY, USER_TOKEN } from '../AuthProvider';
import { roles } from '@/utils/roles';

export const FIRESTORE_USER = 'firestoreUser';

export type RegisterData = {
  email: string;
  password: string;
  uid?: string;
} & Partial<User>;

const useFirebaseAuth = () => {
  const [user, setUser, clearUser] = useLocalStorage<AuthUser | null>({
    key: USER_KEY,
    defaultValue: null,
  });
  const [firestoreUser, setFirestoreUser, clearFirestoreUser] = useLocalStorage<User | null>({
    key: FIRESTORE_USER,
    defaultValue: null,
  });
  const [error, setError] = React.useState<{ login?: string; register?: string; reset?: string }>(
    {},
  );

  const rollbar = useRollbar();
  const router = useRouter();

  // ----- Hooks ------
  React.useEffect(() => {
    if (!user?.uid) {
      clearFirestoreUser();
      return;
    }
    const itemQuery = getQuery({
      path: ['users'],
      order: [{ field: 'name', order: 'asc' }],
      query: [where('email', '==', user.email)],
    });
    return onSnapshot(
      itemQuery,
      { includeMetadataChanges: true },
      (querySnapshot) => {
        const users: User[] = [];
        querySnapshot.forEach((doc) => {
          users.push(doc.data() as User);
        });

        if (users.length === 0) {
          clearFirestoreUser();
        } else {
          const user = users[0];
          setFirestoreUser({
            ...user,
            authId: user.authId || user.googleAuthId || user.facebookAuthId,
          });
        }
      },
      (error: Error) => {
        rollbar.error('useFirebaseAuth - Error on get firestore user', error);
      },
    );
  }, [user?.uid]);

  React.useEffect(() => {
    return firebaseAuth.onIdTokenChanged(async (user) => {
      if (!user) {
        setUser(null);
        clearUser();
        setError({});
        deleteCookie(USER_TOKEN);

        const isAdminRoute = adminRoutes.some((route) => route.path === router.pathname);
        if (isAdminRoute) router.push('/entrar');
      } else {
        setUser(user);
        setError({});
        const token = await user.getIdToken();
        setCookie(USER_TOKEN, token);
      }
    });
  }, []);

  // ----- Methods ------
  function handleAuthError(
    customMessage: string,
    err: unknown,
    options?: { withNotification?: boolean },
  ) {
    const error = err as AuthError;

    // If we show a notification we don't need to show the error in the form
    if (options?.withNotification)
      notifications.show({
        title: 'Não conseguimos concluir a ação',
        message: getUserMessage(error),
        color: 'red',
      });
    else setError((state) => ({ ...state, login: getUserMessage(error) }));

    // If the error is userOnly we don't need to send it to rollbar
    if (UserOnlyErrorCodes.includes(error.code)) return;
    rollbar.error(customMessage, getMessage(error), { error });
  }

  async function handleRegister({ email, password, uid, ...data }: RegisterData) {
    setError({});
    try {
      const createdUser = await createUserWithEmailAndPassword(firebaseAuth, email, password);

      if (createdUser.user) {
        try {
          if (uid) {
            // The uid is for the "accept invite" flow
            await updateData(['users'], uid, {
              authId: createdUser.user.uid,
              acceptedInvite: true,
              email,
            });
          } else {
            // Here is for the actual signup flow
            await createData(['users'], {
              authId: createdUser.user.uid,
              email,
              ...data,
            });
          }

          // User should be logged in after registration
          await handleLogin(email, password);
        } catch (err) {
          handleAuthError('useFirebaseAuth - Error on updating user on firestore', err);
        }
      } else {
        router.push('/');
      }
    } catch (err) {
      handleAuthError('AuthProvider - Error on creating new user', err);
    }
  }

  async function handleLogin(email: string, password: string) {
    setError({});
    try {
      const user = await signInWithEmailAndPassword(firebaseAuth, email, password);
      try {
        const firestoreUser = await checkAndCreateUserOnFirestore(user);
        if (firestoreUser.role === roles.advertiser) {
          router.push('/anunciante');
        } else {
          router.push('/admin');
        }
      } catch (err) {
        handleAuthError('AuthProvider - Error on checking user on Firestore', err);
      }
    } catch (err) {
      handleAuthError('AuthProvider - Falha ao fazer login', err);
    }
  }

  async function handleLogout(doNotEraseFromLocalStorage: string[]) {
    try {
      await signOut(firebaseAuth);
      Object.keys(localStorage).forEach((key) => {
        if (!doNotEraseFromLocalStorage.includes(key)) localStorage.removeItem(key);
      });
      router.reload();
    } catch (err) {
      handleAuthError('AuthProvider - Error on Logout', err, { withNotification: true });
    }
  }

  async function handleResetPwd(email: string) {
    setError({});
    try {
      await sendPasswordResetEmail(firebaseAuth, email);
    } catch (err) {
      handleAuthError('AuthProvider - Error on reseting password', err);
    }
  }

  return [firestoreUser, error, handleLogin, handleLogout, handleRegister, handleResetPwd] as const;
};

export default useFirebaseAuth;
