import { Category, EstablishmentContent, Lesson, Portal } from '@/typings/portal';
import { User } from '@/typings/user';
import { StateCreator } from 'zustand';
import { AppStore } from '../store';
import { Scent } from '@/typings/scent';
import { Banner } from '@/typings/banner';
import { WineColor } from '@/typings/wineColor';
import { ScentFamily } from '@/typings/scentFamily';
import { ColorCategory } from '@/typings/colorCategory';
import { WineStyle } from '@/typings/wineStyle';
import { WineType } from '@/typings/wineType';
import { EstablishmentCategory } from '@/typings/establishmentCategory';
import { Region } from '@/typings/region';
import { ChannelVideo } from '@/typings/channelVideo';
import { BlogPost } from '@/typings/blog';
import { Tag } from '@/typings/tag';
import { VideoCategory } from '@/typings/videoCategory';
import { Confraternity } from '@/typings/confraternity';
import { Event, MonthWithEvents } from '@/typings/event';

export type Entity = keyof Omit<FirebaseSlice, 'updateData'>;

export type FirebaseSlice = {
  users: User[] | null;
  portals: Portal[] | null;
  scents: Scent[] | null;
  banners: Banner[] | null;
  wineColors: WineColor[] | null;
  scentFamilies: ScentFamily[] | null;
  colorCategories: ColorCategory[] | null;
  videoCategories: VideoCategory[] | null;
  establishmentCategories: EstablishmentCategory[] | null;
  wineStyles: WineStyle[] | null;
  wineTypes: WineType[] | null;
  events: Event[] | null;
  regions: Region[] | null;
  channelVideos: ChannelVideo[] | null;
  blogPosts: BlogPost[] | null;
  tags: Tag[] | null;
  confraternities: Confraternity[] | null;
  monthsWithEvents: MonthWithEvents[] | null;
  currentMonthWithEvents: MonthWithEvents[] | null; // Array with just one position
  lessons: Lesson[] | null;
  categories: Category[] | null;
  establishmentsContent: EstablishmentContent[] | null;
  updateData: <DataType>(entity: Entity, callback: (state: DataType) => DataType) => void;
};

export const createFirebaseSlice: StateCreator<
  AppStore,
  [],
  [],
  FirebaseSlice /* Current slice type */
> = (set, get) => ({
  users: null,
  portals: null,
  scents: null,
  banners: null,
  wineColors: null,
  scentFamilies: null,
  colorCategories: null,
  videoCategories: null,
  establishmentCategories: null,
  wineStyles: null,
  wineTypes: null,
  events: null,
  regions: null,
  channelVideos: null,
  blogPosts: null,
  tags: null,
  confraternities: null,
  monthsWithEvents: null,
  currentMonthWithEvents: null,
  lessons: null,
  categories: null,
  establishmentsContent: null,
  updateData: <DataType>(entity: Entity, callback: (state: DataType) => DataType) => {
    const store = get();
    const data = callback((store[entity] || []) as unknown as DataType);
    set({ ...store, [entity]: data });
  },
});
