/* eslint-disable camelcase */
import Rollbar, { Configuration, LogArgument } from 'rollbar';

type LoggingTypes = 'log' | 'error' | 'info' | 'debug' | 'critical' | 'warn' | 'warning';

const clientToken = process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN;
const serverToken = process.env.NEXT_PUBLIC_ROLLBAR_SERVER_TOKEN;

export const hasToken = clientToken || serverToken;

const getRollbarEnvironment = (isClient: boolean) => {
  const project = isClient ? 'client' : 'server';

  return `${project}.${process.env.NEXT_PUBLIC_ROLLBAR_ENV || process.env.NODE_ENV}`;
};

export const getRollbarConfig = (isClient: boolean) => {
  const token = isClient ? clientToken : serverToken;
  return {
    environment: getRollbarEnvironment(isClient),
    accessToken: token || '',
    retryInterval: 1000,
    captureUncaught: true,
    captureUnhandledRejections: true,
    addErrorContext: true,
    onSendCallback: (_, args) => {
      if (token) {
        console.log(`Sending to Rollbar from ${isClient ? 'client' : 'server'} side`, args);
      } else {
        console.log(
          `Error should be sent to Rollbar from ${isClient ? 'client' : 'server'} side`,
          args,
        );
      }
    },
    payload: {
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: process.env.NEXT_BUILD_ID,
          guess_uncaught_frames: true,
        },
      },
    },
  } as Configuration;
};

export const createRollbarInstance = (isClient: boolean) =>
  hasToken ? new Rollbar(getRollbarConfig(isClient)) : undefined;

const rollbarInstance = createRollbarInstance(typeof window !== 'undefined');

const handleRollbarLog =
  (type: LoggingTypes) =>
  (...args: LogArgument[]) => {
    if (rollbarInstance) rollbarInstance[type](...args);
  };

// This rollbar variable should be used on server side ONLY. For client side use rollbar from @rollbar/react provider
const rollbar = {
  log: handleRollbarLog('log'),
  error: handleRollbarLog('error'),
  info: handleRollbarLog('info'),
  debug: handleRollbarLog('debug'),
  critical: handleRollbarLog('critical'),
  warn: handleRollbarLog('warn'),
  warning: handleRollbarLog('warning'),
};

export default rollbar;
