import React, { PropsWithChildren } from 'react';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { Locale } from '@/typings/locale';
import { IntlProvider } from 'react-intl';
import messagesPT from '@/lang/pt-BR.json';
import messagesEN from '@/lang/en-US.json';
import * as S from './Landing.styles';
import { useRouter } from 'next/router';

const menu = {
  'pt-BR': [
    { label: 'Home', url: '#home' },
    { label: 'Teaser', url: '#teaser' },
    { label: 'Quem Somos', url: '#quem-somos' },
    { label: 'Onde Baixar', url: '#onde-baixar' },
    { label: 'Blog', url: '/blog' },
    { label: 'Canal', url: '/canal' },
    { label: 'Press Kit', url: '/press-kit' },
    // { label: 'Anunciar', url: '/anuncie' },
  ],
  'en-US': [
    { label: 'Home', url: '#home' },
    { label: 'Teaser', url: '#teaser' },
    { label: 'About Us', url: '#about-us' },
    { label: 'Download', url: '#download' },
    { label: 'Blog', url: '/blog' },
    { label: 'Channel', url: '/canal' },
    { label: 'Press Kit', url: '/press-kit' },
    // { label: 'Advertise', url: '/anuncie' },
  ],
};

const LandingLayout: React.FC<PropsWithChildren<{ locale: Locale }>> = ({ children, locale }) => {
  const message = locale === 'en-US' ? messagesEN : messagesPT;

  const router = useRouter();

  const ref = React.useRef<HTMLDivElement>(null);

  // Scroll to top when navigate to another page
  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView();
    }
  }, [router.pathname]);

  return (
    <IntlProvider locale={locale || 'en-US'} messages={message}>
      <Header fixed menu={menu[locale]} />
      <S.Main ref={ref}>{children}</S.Main>
      <Footer />
    </IntlProvider>
  );
};

export default LandingLayout;
