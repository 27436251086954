import Header from './components/Header/Header';
import { AppShell } from '@mantine/core';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import Navbar from './components/Navbar/Navbar';
import { useRouter } from 'next/router';
import { adminRoutes, advertiserRoutes } from '@/utils/routes';

export default function AdminLayout({ children }: PropsWithChildren) {
  const [opened, setOpened] = useState(false);

  const router = useRouter();
  const lastRouter = useRef(router.pathname);

  useEffect(() => {
    if (lastRouter.current !== router.pathname) {
      lastRouter.current = router.pathname;
      setOpened(false);
    }
  }, [router.pathname]);

  return (
    <AppShell
      navbarOffsetBreakpoint="sm"
      header={<Header {...{ opened, setOpened }} />}
      navbar={
        <Navbar
          {...{ opened }}
          /* Maybe pass these routes as props to the layout */
          routes={router.pathname.includes('anunciante') ? advertiserRoutes : adminRoutes}
        />
      }
    >
      {children}
    </AppShell>
  );
}
