/**
 * - **admin** - Administrador
 * - **free** - Usuário free
 * - **subscriber** - Usuário assinante
 * - **premium** - Usuário premium
 * - **advertiser** - Usuário anunciante
 */
export const roles = {
  admin: 'admin' as const,
  free: 'free' as const,
  subscriber: 'subscriber' as const,
  premium: 'premium' as const,
  advertiser: 'advertiser' as const,
};

export type Role = keyof typeof roles;

export const rolesWithLabel = [
  { label: 'Administrador', value: roles.admin },
  { label: 'Usuário free', value: roles.free },
  { label: 'Usuário assinante', value: roles.subscriber },
  { label: 'Usuário premium', value: roles.premium },
  { label: 'Anunciante', value: roles.advertiser },
];

export const getRoleLabel = (role?: string) => rolesWithLabel.find((r) => role === r.value)?.label;

export const isAdmin = (role?: string) => role === roles.admin;
