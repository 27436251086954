import React from 'react';
import type { AppProps } from 'next/app';
import ThemeProvider from '@/providers/ThemeProvider';
import RollbarProvider from '@/providers/RollbarProvider';
import AuditAccessibility from '@/utils/reportAcessibility';
import { createRollbarInstance } from '@/utils/rollbar';
import AuthProvider from '@/providers/AuthProvider/AuthProvider';
import { DefaultSeo } from 'next-seo';
import SeoConfig from '@/utils/seo.config';
import { useHydrate } from '@/stores/store';
import { StoreProvider } from '@/providers/ZustandProvider';
import { Route, adminRoutes, advertiserRoutes } from '@/utils/routes';
import { useRouter } from 'next/router';
import AdminLayout from '@/layouts/Admin/Admin';
import LandingLayout from '@/layouts/Landing/Landing';
import Analytics from '@/components/Analytics/Analytics';
import '@/styles/index.css';
import '@/styles/reset.css';
import '@/styles/quill.css';
import FacebookPixel from '@/components/FacebookPixel/FacebookPixel';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import AgeModal from '@/components/AgeModal/AgeModal';

export const OVER_18_KEY = 'over18';

const App = ({ Component, pageProps }: AppProps) => {
  const rollbarInstance = createRollbarInstance(true);
  const store = useHydrate(pageProps.initialStoreState);
  const router = useRouter();
  const locale = pageProps.locale;

  const adminRoutesAndSubRoutes = adminRoutes
    .map((route) => (route.path ? [route] : route.submenus))
    .filter((route) => !!route)
    .flat() as Route[];

  const advertiserRoutesAndSubRoutes = advertiserRoutes
    .map((route) => (route.path ? [route] : route.submenus))
    .filter((route) => !!route)
    .flat() as Route[];

  const [modalOpened, { close: modalClose, open: modalOpen }] = useDisclosure(false);

  const [over18, setOver18, clearOver18] = useLocalStorage<boolean | null>({
    key: OVER_18_KEY,
    defaultValue: null,
    getInitialValueInEffect: false,
  });

  React.useEffect(() => {
    // wait 1 second before checking to open the modal
    setTimeout(() => {
      if (!over18) {
        modalOpen();
      }
    }, 1000);
  }, [over18]);

  return (
    <RollbarProvider instance={rollbarInstance}>
      <StoreProvider store={store}>
        <AuthProvider initialUser={pageProps.initialUser}>
          <ThemeProvider>
            <DefaultSeo {...SeoConfig} />
            {router.pathname === '/admin/update-portal-dashboard' ||
            [...adminRoutesAndSubRoutes, ...advertiserRoutesAndSubRoutes].some(
              (route) => route.path === router.pathname,
            ) ? (
              <AdminLayout>
                <Component {...pageProps} />
              </AdminLayout>
            ) : router.pathname === '/entrar' ||
              router.pathname === '/anunciante/entrar' ||
              router.pathname === '/baixar' ? (
              <Component {...pageProps} />
            ) : (
              <LandingLayout locale={locale}>
                <Analytics />
                <FacebookPixel />
                <Component {...pageProps} />
                <AgeModal
                  opened={modalOpened}
                  onClose={modalClose}
                  setOver18={setOver18}
                  clearOver18={clearOver18}
                  over18={over18}
                />
              </LandingLayout>
            )}
          </ThemeProvider>
        </AuthProvider>
      </StoreProvider>
    </RollbarProvider>
  );
};

AuditAccessibility(React);

export default App;
