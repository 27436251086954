import Image from 'next/image';
import { Box } from '@mantine/core';
import React, { CSSProperties, FunctionComponent } from 'react';
import iconDark from 'public/assets/logos/icon-dark.png';
import logoDark from 'public/assets/logos/logo-dark.png';
import logoLight from 'public/assets/logos/logo-light.png';
import iconLight from 'public/assets/logos/icon-light.png';

interface LogoProps {
  link?: string;
  variant?: 'dark' | 'light' | 'iconLight' | 'iconDark';
  width?: number;
  height?: number;
  alt?: string;
  style?: CSSProperties;
}

const Logo: FunctionComponent<LogoProps> = ({
  link,
  variant = 'dark',
  width = 180,
  style,
  alt = 'Logo Enoteca',
}) => {
  let logoURL;
  switch (variant) {
    case 'dark':
      logoURL = logoDark;
      break;
    case 'light':
      logoURL = logoLight;
      break;
    case 'iconDark':
      logoURL = iconDark;
      break;
    case 'iconLight':
      logoURL = iconLight;
    default:
      logoURL = logoDark;
  }
  return (
    <Box component={link ? 'a' : 'div'} style={style} href={link}>
      <Image
        style={{ objectFit: 'contain' }}
        width={width}
        height={0}
        src={logoURL}
        title={alt}
        alt={alt ? alt : 'Logo Enoteca'}
      />
    </Box>
  );
};

export default Logo;
