import { DefaultSeoProps } from 'next-seo';

// Instead of the default variables, feel free to update the object directly
const defaultTitle = 'Enoteca Mundi | Plataforma de ensino e entretenimento em vinhos';
const defaultDescription =
  'Descubra o mundo dos vinhos com a Enoteca Mundi! Acesse conteúdo completo e aprenda sobre estilos, regiões e técnicas de produção. Encontre os melhores estabelecimentos, registre suas notas de degustação e aproveite uma experiência enriquecedora. Baixe agora e explore a arte dos vinhos!';
const defaultUrl = 'https://enotecamundi.com.br/';

const config: DefaultSeoProps = {
  defaultTitle,
  titleTemplate: `%s | Enoteca Mundi`,
  description: defaultDescription,
  canonical: defaultUrl,
  openGraph: {
    url: defaultUrl,
    title: defaultTitle,
    description: defaultDescription,
    images: [
      {
        url: '/assets/seo-banner.jpg',
        width: 816,
        height: 470,
        alt: 'Logo do Enoteca Mundi',
        type: 'image/jpeg',
      },
    ],
    siteName: 'Enoteca Mundi',
  },
  twitter: {
    handle: '@handle',
    site: '@site',
    cardType: 'summary_large_image',
  },
};

export default config;
