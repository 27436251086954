import React, { PropsWithChildren } from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';
import type Rollbar from 'rollbar';
import { getRollbarConfig } from '@/utils/rollbar';
import ErrorView from '@/views/Error/Error';

const RollbarProvider: React.FC<PropsWithChildren<{ instance?: Rollbar }>> = ({
  children,
  instance,
}) => {
  return (
    <Provider
      {...(instance ? { instance } : { config: getRollbarConfig(true) })}
      instance={instance}
    >
      <ErrorBoundary fallbackUI={ErrorView as unknown as React.ReactNode}>{children}</ErrorBoundary>
    </Provider>
  );
};

export default RollbarProvider;
