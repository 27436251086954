import Logo from '@/components/Logo/Logo';
import Image from 'next/image';
import { Grid, Group, UnstyledButton, Text, Box, Container, Stack } from '@mantine/core';
import { FormattedMessage } from 'react-intl';
import {
  BrandFacebook,
  BrandInstagram,
  BrandYoutube,
  BrandTiktok,
  BrandTwitter,
  BrandWhatsapp,
  Mailbox,
  Map2,
  Phone,
  Building,
  Paperclip,
} from 'tabler-icons-react';
import { motion } from 'framer-motion';

const containerMotion = {
  hidden: {
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
    },
  },
  show: {
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
    },
  },
};
const itemMotionX = {
  hidden: { opacity: 0, x: 50 },
  show: {
    transition: {
      mass: 1,
      duration: 0.6,
      type: 'tween',
    },
    opacity: [0, 0.2, 0.3, 0.5, 1],
    x: 0,
  },
};

const Footer = () => {
  return (
    <footer>
      <Box
        top={100}
        pos="relative"
        py={100}
        bg={'radial-gradient(191.99% 606.79% at 167.12% 74.83%, #480011 19.91%, #962133 74.38%);'}
      >
        <Container size={'xl'}>
          <Grid gutter={50}>
            <Grid.Col md={4}>
              <Logo variant="light" style={{ paddingBottom: '25px' }} />
              <Text component="small" c={'lightText'} size="xs">
                <FormattedMessage id="footer.about" />
              </Text>
              <Stack align="flex-start" mt={25}>
                <Group c={'lightText'}>
                  <Building />
                  <FormattedMessage id="footer.company" />
                </Group>
                <Group c={'lightText'}>
                  <Paperclip />
                  <FormattedMessage id="footer.registerNumber" />
                </Group>
                <Group c={'lightText'}>
                  <Mailbox />
                  <FormattedMessage id="footer.mail" />
                </Group>
                <Group variant="subtle" c={'lightText'}>
                  <Phone />
                  <FormattedMessage id="footer.phone" />
                </Group>
                <Group variant="subtle" c={'lightText'}>
                  <Map2 />
                  <FormattedMessage id="footer.address" />
                </Group>
              </Stack>
            </Grid.Col>

            <Grid.Col md={4}>
              <Text fz={'lg'} weight={600} mb={50}>
                <FormattedMessage id="footer.followUs" />
              </Text>

              <Group>
                <UnstyledButton
                  component="a"
                  href="https://www.facebook.com/enotecamundi"
                  title="Facebook"
                >
                  <BrandFacebook size={35} />
                </UnstyledButton>
                <UnstyledButton
                  component="a"
                  href="https://www.instagram.com/enotecamundi"
                  title="Instagram"
                >
                  <BrandInstagram size={35} />
                </UnstyledButton>
                <UnstyledButton
                  component="a"
                  href="https://www.youtube.com/@enotecamundi3052/featured"
                  title="Youtube"
                >
                  <BrandYoutube size={35} />
                </UnstyledButton>
                <UnstyledButton
                  component="a"
                  href="https://www.tiktok.com/@enotecamundi"
                  title="TikTok"
                >
                  <BrandTiktok size={35} />
                </UnstyledButton>
                <UnstyledButton
                  component="a"
                  href="https://twitter.com/enotecamundi"
                  title="Twitter"
                >
                  <BrandTwitter size={35} />
                </UnstyledButton>
                <UnstyledButton component="a" href="https://wa.me/5521965044220" title="WhatsApp">
                  <BrandWhatsapp size={35} />
                </UnstyledButton>
              </Group>
            </Grid.Col>
            <Grid.Col md={4}>
              <Text fz={'lg'} weight={600} mb={50}>
                <FormattedMessage id="footer.supporter" />
              </Text>
              <motion.div
                variants={containerMotion}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true }}
              >
                <Group align="baseline">
                  <motion.div
                    style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                    variants={itemMotionX}
                    viewport={{ once: true }}
                  >
                    <UnstyledButton
                      bg={'#fff'}
                      style={{ borderRadius: '5px' }}
                      component="a"
                      href="https://www.instagram.com/adrianomattosmoreira2/?hl=pt-br"
                      title="Adriano Mattos Instagram"
                    >
                      <Image
                        width={90}
                        height={90}
                        style={{ objectFit: 'contain' }}
                        src="/assets/adriano-matos-main.png"
                        alt="Adriano Matos"
                      />
                    </UnstyledButton>
                  </motion.div>
                  <motion.div
                    style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                    variants={itemMotionX}
                    viewport={{ once: true }}
                  >
                    <UnstyledButton
                      component="a"
                      href="https://www.instagram.com/agenciapineapple/"
                      title="Agencia Pineapple Instagram"
                    >
                      <Image
                        width={90}
                        height={90}
                        style={{ objectFit: 'contain' }}
                        src="/assets/pineapple.png"
                        alt="Pineapple"
                      />
                    </UnstyledButton>
                  </motion.div>
                  <motion.div
                    style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                    variants={itemMotionX}
                    viewport={{ once: true }}
                  >
                    <UnstyledButton
                      h={'100px'}
                      component="a"
                      href="https://www.appmasters.io/"
                      title="App Masters - Desenvolvimento web e app"
                    >
                      <Image
                        width={90}
                        height={90}
                        style={{ objectFit: 'contain' }}
                        src="/assets/appmasters.png"
                        alt="App Masters"
                      />
                    </UnstyledButton>
                  </motion.div>
                </Group>
              </motion.div>
            </Grid.Col>
          </Grid>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
