import { createColorArray } from '@/utils/colors';
import { MantineThemeColors, MantineThemeOverride } from '@mantine/core';

// Mantine color property expect an array with 10 different shades
export const colors = {
  lightRed: createColorArray('#962134'),
  darkRed: createColorArray('#480011'),
  lightGolden: createColorArray('#AD9A5E'),
  darkGolden: createColorArray('#705e24'),
  goldenGradient: Array(10).fill('linear-gradient(90deg, #AD9A5E 0%, #705e24 100%)'),
  redGradient: Array(10).fill('linear-gradient(90deg, #962134 0%, #480011 100%)'),
  redGradientOpacityLeft: Array(10).fill(
    'linear-gradient(to left, #962134 0%, #480011 85%, #48001180 90%, #48001100 100%)',
  ),
  redGradientOpacityRight: Array(10).fill(
    'linear-gradient(to right, #962134 0%, #480011 85%, #48001180 90%, #48001100 100%)',
  ),
  redGradientOpacityTop: Array(10).fill(
    'linear-gradient(to top, #962134 0%, #480011 85%, #48001180 90%, #48001100 100%)',
  ),
  redGradientOpacityBottom: Array(10).fill(
    'linear-gradient(to bottom, #962134 0%, #480011 85%, #48001180 90%, #48001100 100%)',
  ),
  silverGradient: Array(10).fill('linear-gradient(90deg, #CFCFCF 0%, #ffffff 100%)'),
  lightText: createColorArray('#ffffff'),
  semiLightText: createColorArray('#E6E6E6'),
  darkText: createColorArray('#544A4C'),
  lightBackground: createColorArray('#ffffff'),
  darkBackground: createColorArray('#000000'),
  lightBackgroundGradient: Array(10).fill('linear-gradient(180deg, #F9FAFA 0%, #F5F6F7 100%)'),
};

const lightTheme: MantineThemeOverride = {
  colorScheme: 'dark',
  colors: colors as MantineThemeColors,
  primaryColor: 'lightRed',
  shadows: {
    sm: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.14), rgba(0, 0, 0, 0.14) 0 0.625rem 0.9375rem -0.3125rem, rgba(0, 0, 0, 0.1) 0 0.4375rem 0.4375rem -0.3125rem',
    md: '0 0.0625rem 0.1875rem rgba(0,0,0, 0.14), rgba(0,0,0, 0.14) 0 1.25rem 1.5625rem -0.3125rem, rgba(0,0,0, 0.1) 0 0.625rem 0.625rem -0.3125rem',
    lg: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.14), rgba(0, 0, 0, 0.14) 0 1.75rem 1.4375rem -0.4375rem, rgba(0, 0, 0, 0.1) 0 0.75rem 0.75rem -0.4375rem',
    xl: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.14), rgba(0, 0, 0, 0.14) 0 2.25rem 1.75rem -0.4375rem, rgba(0, 0, 0, 0.1) 0 1.0625rem 1.0625rem -0.4375rem',
    xs: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.14), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.1)',
  },
  defaultGradient: {
    from: 'lightRed',
    to: 'darkRed.5',
    deg: 45,
  },
};

export default lightTheme;
