import { User } from '@/typings/user';
import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { Auth, browserLocalPersistence, getAuth, UserCredential } from 'firebase/auth';
import { getStorage, FirebaseStorage, ref, getDownloadURL, deleteObject } from 'firebase/storage';
import {
  addDoc,
  and,
  collection,
  collectionGroup,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  FieldPath,
  Firestore,
  getCountFromServer,
  getDoc,
  getDocs,
  initializeFirestore,
  limit,
  orderBy,
  OrderByDirection,
  persistentLocalCache,
  persistentMultipleTabManager,
  Query,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QueryFilterConstraint,
  setDoc,
  SetOptions,
  SnapshotOptions,
  Timestamp,
  where,
} from 'firebase/firestore';

import rollbar from './rollbar';

export type Path = [path: string, ...pathSegments: string[]];
export type OrderParams = { field: string | FieldPath; order: OrderByDirection };

const firebaseConfig = JSON.parse(
  process.env.NEXT_PUBLIC_FIREBASE_CONFIG || 'null',
) as FirebaseOptions;

export let firebaseApp: FirebaseApp;
export let firestore: Firestore;
export let firebaseAuth: Auth;
export let storage: FirebaseStorage;

try {
  if (firebaseConfig) {
    firebaseApp = initializeApp(firebaseConfig);
    firebaseAuth = getAuth(firebaseApp);
    firestore = initializeFirestore(firebaseApp, {
      ignoreUndefinedProperties: true,
      localCache:
        typeof window !== 'undefined'
          ? persistentLocalCache({ tabManager: persistentMultipleTabManager() })
          : undefined,
    });
    storage = getStorage(firebaseApp);

    firebaseAuth = getAuth(firebaseApp);
    firebaseAuth.setPersistence(browserLocalPersistence);
  }
} catch (error) {
  rollbar.error('Error on firebase setup', { error });
}

const converter = {
  toFirestore(data: DocumentData): DocumentData {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): DocumentData {
    const data = snapshot.data(options);
    if (data.date) data.date = data.date.toDate();
    if (data.startsAtDate) data.startsAtDate = data.startsAtDate.toDate();
    if (data.finishesAtDate) data.finishesAtDate = data.finishesAtDate.toDate();
    if (data.createdAt && data.createdAt.toDate) data.createdAt = data.createdAt.toDate();
    if (data.updatedAt && data.updatedAt.toDate) data.updatedAt = data.updatedAt.toDate();
    if (data.dateLimit && data.dateLimit.toDate) data.dateLimit = data.dateLimit.toDate();
    if (data.answeredAt && data.answeredAt.toDate) data.answeredAt = data.answeredAt.toDate();
    if (data.validatedAt && data.validatedAt.toDate) data.validatedAt = data.validatedAt.toDate();
    if (data.startDate && data.startDate.toDate) data.startDate = data.startDate.toDate();
    if (data.endDate && data.endDate.toDate) data.endDate = data.endDate.toDate();
    if (data.premiumFinishesAt && data.premiumFinishesAt.toDate)
      data.premiumFinishesAt = data.premiumFinishesAt.toDate();
    return { ...data, id: snapshot.id };
  },
};

// Some firestore hooks need a DOCUMENT arg
export const getDocument = <DocumentData>(path: string, ...pathSegments: string[]) =>
  doc(firestore, path, ...pathSegments).withConverter(converter) as DocumentReference<DocumentData>;

// Some firestore hooks need a COLLECTION arg
export const getCollection = <DocumentData>(path: string, ...pathSegments: string[]) =>
  collection(firestore, path, ...pathSegments).withConverter(
    converter,
  ) as CollectionReference<DocumentData>;

// Some firestore hooks need a QUERY arg
export const getQuery = <DocumentData>({
  path,
  order,
  query: queryParams = [],
  limit: limitValue,
}: {
  path: Path;
  order: [OrderParams, ...OrderParams[]];
  limit?: number;
  query?: [...queryConstraints: QueryConstraint[]];
}) => {
  const ref = getCollection(...path);

  return query(
    ref,
    where('deletedAt', '==', null), // TODO: remove this if we're not using soft delete
    ...(queryParams ? queryParams : []),
    ...(limitValue ? [limit(limitValue)] : []),
    ...order.map(({ field, order }) => orderBy(field, order)),
  ) as Query<DocumentData>;
};

// To use with and | or
export const getCompoundQuery = <DocumentData>({
  path,
  order,
  query: queryParams = [],
  limit: limitValue,
}: {
  path: Path;
  order: [OrderParams, ...OrderParams[]];
  limit?: number;
  query?: [...queryConstraints: QueryFilterConstraint[]];
}) => {
  const ref = getCollection(...path);

  return query(
    ref,
    and(
      where('deletedAt', '==', null), // TODO: remove this if we're not using soft delete
      ...(queryParams ? queryParams : []),
    ),
    ...(limitValue ? [limit(limitValue)] : []),
    ...order.map(({ field, order }) => orderBy(field, order)),
  ) as Query<DocumentData>;
};

export const getCollectionGroupQuery = <DocumentData>({
  collectionName,
  order,
  query: queryParams = [],
  limit: limitValue,
}: {
  collectionName: string;
  order: [OrderParams, ...OrderParams[]];
  limit?: number;
  query?: [...queryConstraints: QueryConstraint[]];
}) => {
  const ref = collectionGroup(firestore, collectionName);

  return query(
    ref,
    where('deletedAt', '==', null), // TODO: remove this if we're not using soft delete
    ...(queryParams ? queryParams : []),
    ...(limitValue ? [limit(limitValue)] : []),
    ...order.map(({ field, order }) => orderBy(field, order)),
  ) as Query<DocumentData>;
};

/**
 * Create new document on firestore
 * @param path
 * @param data
 * @param user
 */
export const createData = async <T>(path: Path, data: T) => {
  const collection = getCollection<T>(...path);
  return addDoc(collection, { ...data, createdAt: Timestamp.now(), deletedAt: null });
};

/**
 * Update document on firestore
 * @param path
 * @param id
 * @param data
 * @param options
 */
export const updateData = async <T>(path: Path, id: string, data: T, options?: SetOptions) => {
  const item = getDocument<T>(...path, id);
  return setDoc(item, { ...data, updatedAt: Timestamp.now() }, options || { merge: true });
};

/**
 * Delete document on firestore
 * @param path
 * @param id
 * @param data
 * @param options
 */
export const softDelete = async (path: Path, id: string) => {
  return updateData(path, id, { deletedAt: Timestamp.now() }, { merge: true });
};

/**
 * Get collection data from firestore
 * @param query
 */
export const getCollectionData = async <T>(query: Query<T>) => {
  const { docs } = await getDocs<T>(query);
  const data = docs.map((d) => ({ ...(d.data() as T), id: d.id } as T));
  return data;
};

/**
 * Get document data from firestore
 * @param query
 */
export const getDocumentData = async <T>(path: Path) => {
  const doc = await getDoc<T>(getDocument(...path));

  return { ...(doc.data() as T), id: doc.id } as T;
};

/**
 * Check if the user is already created on firestore
 * If not, create it
 * @param user
 */
export const checkAndCreateUserOnFirestore = async (
  user: UserCredential,
): Promise<Partial<User>> => {
  const { uid, email, displayName } = user.user;
  const query = getQuery<User>({
    path: ['users'],
    order: [{ field: 'email', order: 'asc' }],
    query: [where('authId', '==', uid), where('email', '==', email)],
  });

  const querySnapshot = await getDocs<User>(query);

  const queryResult = querySnapshot.docs.map((doc) => doc.data());

  if (queryResult.length) return queryResult[0];

  await createData(['users'], { email, name: displayName, authId: uid });
  return { email: email || undefined, name: displayName || undefined, authId: uid };
};

export const checkIfUserExists = async (type: 'email' | 'cpf', value: string) => {
  const query = getQuery({
    path: ['users'],
    order: [{ field: 'email', order: 'asc' }],
    query: [where(type, '==', value)],
  });

  const querySnapshot = await getDocs(query);

  const [user] = querySnapshot.docs.map((doc) => ({ uid: doc.id, data: doc.data() }));

  return user as { uid: string; data: User };
};

// Storage
export const getStorageRef = (url: string) => ref(storage, url);

export const deleteFile = async (url: string) => {
  try {
    const ref = getStorageRef(url);
    return await deleteObject(ref);
  } catch (e) {
    if ((e as Error).message.includes('does not exist')) {
      return;
    }
    throw e;
  }
};

export const getImageUrl = (path: string) => {
  return getDownloadURL(getStorageRef(path));
};

export const getCount = async (
  path: Path,
  queryParams: QueryFilterConstraint[],
): Promise<number> => {
  const ref = getCollection(...path);
  const queryResult = query(ref, and(where('deletedAt', '==', null), ...queryParams));
  const snapshot = await getCountFromServer(queryResult);
  return snapshot.data().count;
};
