import Link from 'next/link';
import * as S from './Header.styles';
import { Container, Drawer, NativeSelect, Select, Avatar } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Menu2, ChevronDown } from 'tabler-icons-react';
import { forwardRef } from 'react';
import { useRouter } from 'next/router';
import Logo from '../../../../components/Logo/Logo';
import Image from 'next/image';
import { scrollTo } from '@/utils/scroll';

interface HeaderProps {
  fixed?: boolean;
  menu?: { label: string; url: string }[];
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  value: string;
}

const MobileSelectItem = forwardRef<HTMLDivElement, ItemProps>(({ value, ...props }, ref) => {
  return (
    <div ref={ref} {...{ ...props, value }}>
      <Avatar src={`/assets/flag_${value}.png`} />
    </div>
  );
});

export default function Header({ fixed, menu }: HeaderProps) {
  const router = useRouter();
  const [opened, { open, close }] = useDisclosure(false);

  const langSelectorHandler = (e: React.FormEvent<HTMLSelectElement>) => {
    router.push('/', '/', { locale: e.currentTarget.value });
  };

  return (
    <S.Header fixed={fixed}>
      <Container w="100%" size={'xl'} p={0}>
        <S.StyledFlex justify={'space-between'} align={'center'}>
          <S.BurgerButton onClick={open} aria-label="Abrir menu de navegação" mr="auto">
            <S.BurgerMenu key={999}>
              <Menu2 size={30} strokeWidth={2} />
            </S.BurgerMenu>
          </S.BurgerButton>
          <Logo
            link="/"
            width={120}
            style={{
              padding: '0 1rem',
            }}
          />
          <S.LangSelect>
            <Select
              styles={{ input: { width: 1 }, label: { display: 'none' } }}
              onChange={(value) => value && router.push('/', '/', { locale: value })}
              rightSection={<ChevronDown size="1rem" />}
              icon={
                <Image
                  width={22}
                  height={22}
                  src={`/assets/flag_${router.locale}.png`}
                  alt="Language flag"
                  title="Language flag"
                />
              }
              aria-label="Selecione o idioma"
              defaultValue={router.locale}
              value={router.locale}
              itemComponent={MobileSelectItem}
              data={[
                { value: 'pt-BR', label: 'Português brasileiro' },
                { value: 'en-US', label: 'English' },
              ]}
            />
          </S.LangSelect>
          <ul>
            {menu &&
              menu.map((e, i) => {
                const isRoute = e.url.includes('/');

                return (
                  <S.NavLink key={i}>
                    <Link
                      title={e.label}
                      href={isRoute ? e.url : router.route === '/' ? e.url : `/${e.url}`}
                      onClick={
                        isRoute
                          ? () => close()
                          : (event) => {
                              if (router.route === '/') event.preventDefault();
                              scrollTo(e.url, undefined, router.route);
                            }
                      }
                    >
                      {e.label}
                    </Link>
                  </S.NavLink>
                );
              })}
            <Drawer zIndex={11} position="right" size={'60%'} opened={opened} onClose={close}>
              {menu &&
                menu.map((e, i) => {
                  const isRoute = e.url.includes('/');
                  return (
                    <S.NavLink data-mobile mobile key={i}>
                      <Link
                        href={isRoute ? e.url : router.route === '/' ? e.url : `/${e.url}`}
                        onClick={
                          isRoute
                            ? undefined
                            : (event) => {
                                if (router.route === '/') event.preventDefault();
                                scrollTo(e.url, undefined, router.route);
                                close();
                              }
                        }
                      >
                        {e.label}
                      </Link>
                    </S.NavLink>
                  );
                })}
            </Drawer>
            <S.NavLink>
              <NativeSelect
                onChange={langSelectorHandler}
                rightSection={<ChevronDown size="1rem" />}
                style={{ cursor: 'pointer', padding: '0 1rem' }}
                icon={
                  <Image
                    width={22}
                    height={22}
                    src={`/assets/flag_${router.locale}.png`}
                    alt="Language flag"
                    title="Language flag"
                  />
                }
                defaultValue={router.locale}
                data={[
                  { value: 'pt-BR', label: 'Port.' },
                  { value: 'en-US', label: 'Eng.' },
                ]}
              />
            </S.NavLink>
          </ul>
        </S.StyledFlex>
      </Container>
    </S.Header>
  );
}
