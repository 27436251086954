import { User } from '@/typings/user';
import React, { PropsWithChildren } from 'react';
import useFirebaseAuth, { RegisterData } from './hooks/useFirebaseAuth';

type AuthContextType = {
  isAuthenticated: boolean;
  user: User | null;
  authError?: { login?: string; register?: string; reset?: string };
  login: (password: string, email: string) => Promise<void>;
  logout: (doNotDeleteFromLocalStorage: string[]) => void;
  signUp: (data: RegisterData) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
};

export const USER_KEY = 'user';

export const USER_TOKEN = 'token';

const AuthContext = React.createContext({} as AuthContextType);

const AuthProvider: React.FC<PropsWithChildren<{ initialUser?: User }>> = ({
  children,
  initialUser,
}) => {
  // TODO: we will get the user from firebase for now. Change it if you are not using firebase auth on the app
  const [user, authError, login, logout, signUp, resetPassword] = useFirebaseAuth();

  return (
    // Keep InitialLoader wrapper if you keep authentication on client side
    // <InitialLoader loading={isLoading}>
    <AuthContext.Provider
      value={{
        user: user || (initialUser as User),
        login,
        signUp,
        logout,
        isAuthenticated: !!user,
        resetPassword,
        authError,
      }}
    >
      {children}
    </AuthContext.Provider>
    // </InitialLoader>
  );
};

export const useAuth = () => React.useContext(AuthContext);

export default AuthProvider;
