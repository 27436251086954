// IMPORTS
// -----------------------------------------
import React from 'react';
import { NextSeo } from 'next-seo';
import { Button, Text, Title } from '@mantine/core';
import { ViewContainer } from './Error.styles';
import Image from 'next/image';
import { portalIconsConstraints } from '@/utils/constraints';
import { useRollbar } from '@rollbar/react';

type Props = {
  statusCode?: number;
  error?: Error | null;
};

const Error: React.FC<Props> = (props) => {
  const logging = useRollbar();
  React.useEffect(() => {
    if (props.statusCode !== 404)
      logging.error(`[${props.statusCode}] User on the error page`, {
        ...props,
      });
  }, []);

  const message = React.useMemo(() => {
    return props.statusCode === 404
      ? 'Ops, não encontramos o que você está procurando'
      : 'Desculpe, houve um erro inesperado, mas nosso time já foi avisado e vamos corrigir em breve';
  }, [props.statusCode, props.error]);

  return (
    <>
      <NextSeo noindex />
      <ViewContainer>
        {props.statusCode === 404 && (
          <>
            <Image
              quality={50}
              width={150}
              height={150}
              src={portalIconsConstraints['Icon-Barrel']}
              alt="Ícone de serviço escolhido"
            />
            <Title mb="sm">{props.statusCode || 500}</Title>
          </>
        )}
        <Text mb="md">{message}</Text>
        {(props.statusCode === 500 || props.statusCode === 404 || !props.statusCode) && (
          <Button onClick={() => (window.location.href = '/')}>Voltar para o início</Button>
        )}
      </ViewContainer>
    </>
  );
};

export default Error;
