export function getInitials(name?: string) {
  if (!name) return undefined;

  const [firstName, lastName] = name.split(' ');

  if (!firstName) return undefined;
  if (!lastName) return `${firstName.slice(0, 2)}`;

  const firstLetter = firstName[0] || '';
  const secondLetter = lastName[0] || firstName[1] || '';

  if (!firstLetter && !secondLetter) return undefined;

  return `${firstLetter}${secondLetter}`;
}
