type FacebookPixelEvent = {
  name: string;
  parameters?: facebook.Pixel.ViewContentParameters;
  options?: facebook.Pixel.EventIDOptions;
};

export const PageView = () => {
  window.fbq('track', 'PageView');
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (eventData: FacebookPixelEvent) => {
  // Logging on dev to help debugging
  if (process.env.NODE_ENV === 'development') {
    // Cleaning undefined from the object to help visualization
    Object.keys(eventData).forEach(
      (key) =>
        eventData[key as keyof FacebookPixelEvent] === undefined &&
        delete eventData[key as keyof FacebookPixelEvent],
    );
    console.log(`Trying to send ${eventData.name} action to fbq`, eventData);
  }

  window.fbq('track', eventData.name, eventData.parameters || {}, eventData.options);
};
