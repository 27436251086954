import { MantineProvider } from '@mantine/core';
import React, { PropsWithChildren } from 'react';
import { Notifications } from '@mantine/notifications';
import globalStyles from '@/styles/global';
import darkTheme from '@/styles/themes/dark';
import defaultTheme from '@/styles/themes/default';
import RouterTransition from '@/components/RouterTransition/RouterTransition';

type Props = PropsWithChildren;

const ThemeContext = React.createContext({});

const ThemeProvider: React.FC<Props> = ({ children }) => {
  const theme = darkTheme;

  return (
    <ThemeContext.Provider value={{}}>
      <MantineProvider
        withNormalizeCSS
        withGlobalStyles
        theme={{
          globalStyles,
          ...defaultTheme,
          ...theme,
        }}
      >
        <Notifications position="top-right" />
        <RouterTransition />
        {children}
      </MantineProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);

export default ThemeProvider;
