import React from 'react';
import { Button, Flex, Modal, Text, Title, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import Logo from '../Logo/Logo';

type AgeModalProps = {
  opened: boolean;
  onClose: () => void;
  setOver18: (isOver18: boolean) => void;
  clearOver18: () => void;
  over18: boolean | null;
};

const AgeModal: React.FC<AgeModalProps> = ({ opened, onClose, setOver18, clearOver18, over18 }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [localAge, setLocalAge] = React.useState<boolean | null>(over18);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      centered
      overlayProps={{
        blur: 15,
      }}
      size="auto"
      styles={{
        body: {
          ...(isMobile && { padding: 0, height: '100%' }),
        },
        header: {
          ...(isMobile && { marginTop: 60, marginBottom: 10 }),
        },
      }}
      fullScreen={isMobile}
      id="modal-age"
      style={{ position: 'absolute', height: '100%' }}
      transitionProps={{ transition: 'slide-up', duration: 500 }}
    >
      <Flex
        direction="column"
        justify="center"
        align="center"
        style={{ height: '100%' }}
        p={20}
        gap={5}
      >
        <Title size={20}>Seja bem-vindo(a):</Title>
        <Logo width={100} />
        <Text size={15} align="center">
          {localAge === false
            ? 'Você precisa ter 18 anos ou mais para acessar nosso conteúdo.'
            : 'Você tem 18 anos ou mais?'}
        </Text>
        <Flex
          direction={isMobile ? 'column-reverse' : 'row'}
          justify="center"
          align="center"
          w="100%"
        >
          {localAge === false ? (
            <Button
              m={5}
              w={200}
              variant="outline"
              onClick={() => {
                clearOver18();
                window.close();
              }}
            >
              Sair
            </Button>
          ) : (
            <>
              <Button
                m={5}
                w={200}
                variant="outline"
                style={{ color: theme.colors.lightText[1] }}
                onClick={() => {
                  // setOver18(false);
                  setLocalAge(false);
                }}
              >
                NÃO
              </Button>
              <Button
                m={5}
                w={200}
                onClick={() => {
                  setOver18(true);
                  onClose();
                }}
              >
                SIM
              </Button>
            </>
          )}
        </Flex>
        {localAge === null && (
          <Flex direction="column">
            <Text size={12} align="center">
              Beba com moderação.
            </Text>
            <Text size={12} align="center">
              Não compartilhe com menores de 18 anos.
            </Text>
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};

export default AgeModal;
